@charset "UTF-8";
/* HTML5 display-role reset for older browsers */
html {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
body {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  line-height: 1;
}
div {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
span {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
applet {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
object {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
iframe {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
h1 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
h2 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
h3 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
h4 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
h5 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
h6 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
p {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
blockquote {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  quotes: none;
}
blockquote:before {
  content: '';
  content: none;
}
blockquote:after {
  content: '';
  content: none;
}
pre {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
abbr {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
acronym {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
address {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
big {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
cite {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
code {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
del {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
dfn {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
em {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
img {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
ins {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
kbd {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
q {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  quotes: none;
}
q:before {
  content: '';
  content: none;
}
q:after {
  content: '';
  content: none;
}
s {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
samp {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
small {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
strike {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
strong {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
sub {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
sup {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
tt {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
var {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
b {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
u {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
i {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
center {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
dl {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
dt {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
dd {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
ol {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  list-style: none;
}
ul {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  list-style: none;
}
li {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
fieldset {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
form {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
label {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
legend {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
table {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border-collapse: collapse;
  border-spacing: 0;
}
caption {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
tbody {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
tfoot {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
thead {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
tr {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
th {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
td {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  display: block;
}
aside {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  display: block;
}
canvas {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
}
details {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  display: block;
}
embed {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
figure {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  display: block;
}
figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  display: block;
}
footer {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  display: block;
}
header {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  display: block;
}
hgroup {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  display: block;
}
menu {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  display: block;
}
nav {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  display: block;
}
output {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
ruby {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
section {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  display: block;
}
summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
time {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
mark {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
audio {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
.col-md-7 {
  width: 58.33333333%;
  float: left;
}
.col-md-5 {
  width: 41.66666667%;
  float: left;
}
.row {
  clear: both;
}
.nt-icon {
  background: transparent no-repeat center center;
  display: inline-block;
  vertical-align: middle;
  height: 24px;
  width: 24px;
  transition: opacity 0.3s ease;
}
.nt-icon.nt-small {
  width: 24px;
  height: 24px;
}
.nt-icon.nt-large {
  width: 48px;
  height: 48px;
}
.nt-icon.nt-inverse {
  background-position: bottom center;
}
.nt-icon.nt-icon-envelope.nt-small {
  background-image: url("https://static.datakortet.no/norsktestno/icons/24.envelope.svg");
}
.nt-icon.nt-icon-lock.nt-small {
  background-image: url("https://static.datakortet.no/norsktestno/icons/24.lock.svg");
}
.nt-icon.nt-icon-bag.nt-large {
  background-image: url("https://static.datakortet.no/norsktestno/icons/48.bag.png");
}
.nt-icon.nt-icon-sun.nt-large {
  background-image: url("https://static.datakortet.no/norsktestno/icons/48.sun2.svg");
}
.nt-icon.nt-icon-boat.nt-large {
  background-image: url("https://static.datakortet.no/norsktestno/icons/48.boat.svg");
}
.nt-icon.nt-icon-ball.nt-large {
  background-image: url("https://static.datakortet.no/norsktestno/icons/48.ball2.svg");
}
.nt-icon.nt-icon-form.nt-large {
  background-image: url("https://static.datakortet.no/norsktestno/icons/48.form.png");
}
.nt-icon.nt-icon-dk.nt-large {
  background-image: url("https://static.datakortet.no/norsktestno/icons/48.datakortet-at.svg");
}
.nt-icon.nt-icon-arrow-right.nt-small {
  background-image: url("https://static.datakortet.no/norsktestno/icons/24.arrow.right.svg");
}
.nt-icon.nt-icon-arrow-left.nt-small {
  background-image: url("https://static.datakortet.no/norsktestno/icons/24.arrow.left.svg");
}
.nt-icon.nt-icon-pointer-right.nt-small {
  background-image: url("https://static.datakortet.no/norsktestno/icons/24.pointer.right.svg");
}
.nt-icon.nt-icon-pointer-left.nt-small {
  background-image: url("https://static.datakortet.no/norsktestno/icons/24.pointer.left.svg");
}
/*
    This file should only contain stylings of basic elements.
    (i.e. top-level tags).

 */
* {
  box-sizing: border-box;
}
html {
  background-color: #252d2e;
  color: rgba(255, 255, 255, 0.3);
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-weight: 300 !important;
  font-stretch: normal;
  font-size: 12pt;
  line-height: 1;
  font-family: "Exo 2", sans-serif !important;
}
a {
  color: inherit;
  text-decoration: none;
  text-transform: inherit;
  transition: color 0.3s ease;
}
a:hover {
  color: #fdb827;
  text-decoration: underline;
}
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0;
}
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
  margin-bottom: 0;
}
.nt-section-content-block h1,
.nt-section-content-block h2,
.nt-section-content-block h3,
.nt-section-content-block h4,
.nt-section-content-block h5,
.nt-section-content-block h6 {
  font-weight: 200;
  text-align: left;
}
.nt-section-content-block h1 {
  font-size: 3rem;
  margin: 40px 0;
}
.nt-section-content-block h2 {
  color: rgba(68, 68, 68, 0.6);
  font-size: 1.4rem;
  margin: 40px 0 60px;
}
.nt-section-content-block h3 {
  color: inherit;
  font-size: 1.4rem;
  margin: 60px 0 40px;
}
.nt-section-content-trivia h1,
.nt-section-content-trivia h2,
.nt-section-content-trivia h3,
.nt-section-content-trivia h4,
.nt-section-content-trivia h5,
.nt-section-content-trivia h6 {
  color: inherit;
  font-size: 1.4rem;
  text-align: left;
  margin: 24px 0;
}
@media screen and (max-width: 910px) {
  .nt-section-content-block h1 {
    font-size: 2.4rem;
    margin-bottom: 20px;
  }
  .nt-section-content-block h2 {
    margin: 30px 0 40px;
  }
  .nt-section-content-block h3 {
    margin: 30px 0 20px;
  }
  .nt-section-content-trivia h1 {
    font-size: 1.4rem;
  }
}
.nt-button {
  background-color: transparent;
  border: none;
  border-radius: 100px;
  box-shadow: inset 0 0 3px #444;
  color: #444;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 200%;
  outline: none;
  padding: 0 2rem;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
  vertical-align: baseline;
  white-space: nowrap;
}
.nt-button:hover {
  background-color: #444;
  color: #fff;
}
.nt-button:hover .nt-icon {
  opacity: 1;
}
.nt-button .nt-icon {
  opacity: 0.6;
}
.nt-button.nt-inverse {
  box-shadow: inset 0 0 3px #fff;
  color: #fff;
}
.nt-button.nt-inverse:hover {
  background-color: #fff;
  color: #444;
}
.nt-button.nt-primary {
  background-color: #fdb827;
  box-shadow: none;
  color: #444;
}
.nt-button.nt-primary:hover {
  background-color: #fff;
  color: #444;
}
.nt-button.nt-cta {
  background-color: #fff;
  box-shadow: none;
  color: #444;
}
.nt-button.nt-cta:hover {
  background-color: #fdb827;
  color: #444;
}
.nt-button.nt-large {
  line-height: 4rem;
  padding: 0 2rem;
}
.nt-button-label {
  display: inline-block;
  vertical-align: middle;
}
.nt-link-label {
  display: inline-block;
  vertical-align: middle;
}
.nt-logo {
  background-size: contain;
  display: inline-block;
  height: 50px;
  vertical-align: top;
  width: 165px;
}
.nt-logo.nt-inverse img.logo {
  width: 100%;
}
.nt-section {
  background-color: #fff;
  color: #444;
  padding: 60px 20px 80px;
}
.nt-section-body {
  margin: auto;
  max-width: 960px;
}
.nt-section-title {
  font-size: 2rem;
  font-weight: 200;
  line-height: 1.2;
  margin: 30px 0;
  text-align: center;
}
.nt-section-title:first-child {
  margin-top: 0;
}
.nt-section-title:last-child {
  margin-bottom: 0;
}
.nt-section-summary {
  text-align: center;
  font-size: 1.2rem;
  width: 50%;
  margin: auto;
  line-height: 1.4;
}
.nt-actions {
  text-align: center;
  margin: -0.4rem;
}
.nt-action {
  display: inline-block;
  vertical-align: top;
  margin: 0.4rem;
}
.nt-advantages-list {
  text-align: center;
}
.nt-advantages-list-item {
  display: inline-block;
  vertical-align: top;
  width: 33%;
}
.nt-advantage-metric {
  background: transparent url("https://static.datakortet.no/norsktestno/images/circle.dotted.svg") center no-repeat;
  display: block;
  font-size: 1.8rem;
  font-weight: 200;
  height: 150px;
  line-height: 150px;
  margin: auto;
  width: 150px;
}
.nt-advantage-description {
  display: block;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.4;
  margin: 2rem auto 0;
  width: 40%;
}
.nt-section-content-body {
  line-height: 1.2;
}
.nt-section-content-block img {
  display: block;
  margin: 40px auto;
  max-width: 100%;
}
.nt-section-content-block p {
  text-align: left;
  line-height: 1.4;
  margin: 0.8rem auto;
}
.nt-section-content-block ul {
  text-align: left;
  line-height: 1.4;
  margin: 0.8rem auto;
}
.nt-section-content-block ol {
  text-align: left;
  line-height: 1.4;
  margin: 0.8rem auto;
}
.nt-section-content-block li {
  border-bottom: solid 1px #f0f0f0;
  display: block;
  padding-left: 24px;
  position: relative;
}
.nt-section-content-block li:before {
  background-image: url("https://static.datakortet.no/norsktestno/icons/24.pointer.right.svg");
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 24px;
  content: '';
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 6px;
}
.nt-section-content-block li:last-child {
  border-color: transparent;
}
.nt-section-content-block .logo {
  margin-top: 0;
  margin-bottom: 5px;
  float: left;
}
.nt-section-content-block .logo div {
  vertical-align: middle;
  display: inline-block;
}
.nt-section-content-block .logo img {
  width: 50%;
  display: block;
  vertical-align: middle;
  margin: auto;
}
.nt-section-content-block .logo .logo-text-box img {
  margin-bottom: 0;
  margin-left: 0;
}
.nt-section-content-trivia {
  background-color: #2b3146;
  color: #fff;
  font-weight: 200;
  margin: 60px auto;
  padding: 24px;
  max-width: 620px;
  margin-top: 3em;
}
.nt-section-content-trivia li {
  border-color: transparent;
}
.nt-section-content-trivia ul {
  text-align: left;
  line-height: 1.4;
  margin: 0.8rem auto;
}
.nt-section-content-trivia ul p {
  font-size: 0.7em;
}
.ingress {
  font-style: italic;
  font-weight: 200;
}
.quote {
  margin: 40px;
}
.quote p {
  font-style: italic;
  font-size: 1.4em;
  font-weight: 200;
}
.quote .sign h3 {
  font-style: normal;
}
.screenshot {
  width: 100%;
}
.screenshot p {
  text-align: center;
  margin-bottom: 40px;
}
.screenshot img {
  float: left;
  border: 2px solid #808080;
  background-color: #808080;
  margin-right: 4px;
  border-radius: 25px;
  padding: 20px;
  margin-bottom: 10px;
}
.screenshot.small {
  width: 65%;
  margin: 3em auto 60px;
}
.nt-section-header {
  border-bottom: solid 1px #f0f0f0;
  padding-top: 20px;
  padding-bottom: 20px;
}
.nt-section-header-landing {
  background-color: transparent;
  color: #fff;
  position: relative;
}
.nt-section-header-landing:before {
  background: transparent url("https://static.datakortet.no/norsktestno/images/header-bg-landing3.jpg") center;
  background-size: cover;
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0.2;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  pointer-events: none;
}
.nt-section-body-header-landing {
  padding: 120px 0 190px 0;
}
.nt-section-body-header-landing .nt-section-title {
  font-size: 3rem;
  font-weight: 100;
  margin: 0 auto 40px;
  max-width: 640px;
}
.nt-navigation {
  position: relative;
  padding: 18px 0;
}
.nt-navigation .nt-logo {
  display: inline-block;
  vertical-align: middle;
  width: 25%;
}
ul.nt-site-menu {
  list-style-type: none;
  display: inline-block;
  font-size: 0.85rem;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  overflow: hidden;
}
ul.nt-site-menu li.icon {
  display: none;
}
ul.nt-site-menu li {
  float: left;
  height: 50px;
  line-height: 32px;
}
ul.nt-site-menu.sub {
  width: 73%;
}
ul.nt-site-menu.sub li.nt-site-menu-item.button {
  float: right !important;
}
li.nt-site-menu-item a {
  display: inline-block;
  color: #f2f2f2;
  text-align: center;
  padding: 10px 8px;
  transition: 0.3s;
  font-size: 0.85rem;
}
li.button.nt-site-menu-item a {
  padding: 0 32px 0 32px;
  margin-left: 20px;
}
.nt-section-footer {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.8);
}
footer.nt-section-footer .legal {
  font-size: 60%;
  position: relative;
  left: 14px;
  bottom: -50px;
}
footer.nt-section-footer .legal a {
  margin: 14px;
}
.nt-footer-block {
  display: inline-block;
  line-height: 1.6;
  padding-right: 40px;
  vertical-align: top;
  width: 24%;
}
.nt-footer-block-title {
  color: #fff;
  font-size: 0.85rem;
  margin: 12px 0;
  text-transform: uppercase;
}
.nt-footer-block-list {
  font-size: 1.1rem;
  font-weight: 200;
  margin: 24px 0;
}
.nt-footer-block-list > li {
  display: block;
  margin: 12px 0;
}
.nt-footer-block-legal {
  font-size: 0.8rem;
  padding: 0;
  width: 100%;
}
.nt-footer-block-legal a {
  color: #fdb827;
}
.nt-footer-block-legal .nt-footer-block-body {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
.nt-footer-block-legal-copyright {
  text-align: left;
}
.nt-footer-block-legal-site-credit {
  text-align: right;
}
.nt-section-projects {
  background-color: #f0f0f0;
}
.nt-projects-list {
  margin: -10px;
  text-align: center;
}
.nt-projects-list-item {
  display: inline-block;
  padding: 10px;
  vertical-align: top;
}
.nt-project-tile {
  background-color: #fff;
  display: block;
  width: 300px;
}
.nt-project-tile:hover {
  color: inherit;
}
.nt-project-tile:hover .nt-project-image:after {
  transform: scale(1.2);
}
.nt-project-tile.nt-inverse {
  background-color: #2b3146;
  color: #fff;
}
.nt-project-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: 210px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.nt-project-image:after {
  background-image: inherit;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(1);
  transition: transform 0.6s ease;
}
.nt-project-info {
  display: block;
  height: 230px;
  position: relative;
  text-align: left;
}
.nt-project-title {
  font-size: 1.6rem;
  font-weight: 200;
  padding: 24px;
  position: absolute;
  top: 0;
}
.nt-project-summary {
  bottom: 0;
  line-height: 1.4;
  padding: 24px;
  position: absolute;
}
.nt-section-body-projects p.nt-section-summary {
  margin-bottom: 20px;
}
.nt-section-quick-tests {
  background-color: #f0f0f0;
  color: #444;
  padding-top: 0;
  padding-bottom: 0;
}
.nt-section-quick-tests a {
  color: inherit;
}
.nt-quick-tests-list {
  text-align: center;
}
.nt-quick-tests-list-item {
  display: inline-block;
  vertical-align: bottom;
  width: 24%;
}
.nt-quick-test-link {
  display: block;
  padding: 60px 20px;
  text-align: left;
  white-space: nowrap;
}
.nt-quick-test-link:hover {
  box-shadow: inset 0 -3px 0 0 #fdb827;
}
.nt-quick-test-label {
  display: inline-block;
  max-width: 80%;
  padding-left: 20px;
  vertical-align: middle;
  white-space: normal;
  font-weight: 400;
}
.nt-section-solution-constructor {
  background-color: #2b3146;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 200;
}
.tooltip {
  outline: none;
}
.tooltip .tip {
  z-index: 10;
  display: none;
  padding: 14px 20px 14px 20px;
  margin-top: 30px;
  margin-left: -100px;
  width: 220px;
  max-width: 220px;
  height: auto;
  max-height: 170px;
  line-height: 16px;
  overflow: hidden;
  float: right;
  white-space: normal;
  border-radius: 4px;
  box-shadow: 2px 2px 4px #ccc;
}
.tooltip .tip.left {
  margin-left: -250px;
}
.tooltip .tip.above {
  margin-top: -150px;
}
.tooltip strong {
  line-height: 30px;
}
.tooltip:hover {
  text-decoration: none;
}
.tooltip:hover .tip {
  display: inline;
  position: absolute;
  color: #111;
  border: 1px solid #dca;
  background: #fffaf0;
}
.callout {
  z-index: 20;
  position: absolute;
  top: 30px;
  border: 0;
  left: -12px;
}
.nt-section-body-solution-constructor .nt-section-title {
  color: #fff;
}
.nt-solution-constructor-wrapper {
  overflow: hidden;
}
.nt-solution-constructor-steps {
  margin: 60px 0;
  white-space: nowrap;
}
.nt-solution-constructor-step {
  display: inline-block;
  position: relative;
  text-align: center;
  vertical-align: top;
  width: 20%;
}
.nt-solution-constructor-step:hover .nt-solution-constructor-step-content {
  background-color: #fdb827;
}
.nt-solution-constructor-step:hover .nt-solution-constructor-step-icon {
  display: block;
}
.nt-solution-constructor-step:hover .nt-solution-constructor-step-number {
  display: none;
}
.nt-solution-constructor-step:after {
  background-color: #fff;
  border-radius: 10px;
  content: '';
  height: 8px;
  left: 100%;
  opacity: 0.1;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
}
.nt-solution-constructor-step:last-child:after {
  display: none;
}
.nt-solution-constructor-step.nt-active .nt-solution-constructor-step-content {
  background-color: #fdb827;
}
.nt-solution-constructor-step.nt-active .nt-solution-constructor-step-icon {
  display: block;
}
.nt-solution-constructor-step.nt-active .nt-solution-constructor-step-number {
  display: none;
}
.nt-solution-constructor-step-content {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 150px;
  height: 140px;
  margin: auto;
  position: relative;
  width: 140px;
}
.nt-solution-constructor-step-icon {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  display: none;
}
.nt-solution-constructor-step-number {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.4rem;
  display: block;
  text-transform: uppercase;
}
.nt-solution-constructor-stages {
  color: #fff;
  margin: 20px 0;
}
.nt-solution-constructor-stage {
  display: inline-block;
  vertical-align: top;
  width: 19.696%;
}
.nt-solution-constructor-stage:first-child .nt-solution-constructor-stage-progress:before {
  display: block;
}
.nt-solution-constructor-stage.nt-active .nt-solution-constructor-stage-progress {
  background-color: #fdb827;
}
.nt-solution-constructor-stage-number {
  font-size: 1em;
  font-weight: 300;
  white-space: nowrap;
}
.nt-solution-constructor-stage-progress {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  height: 6px;
  margin: 20px 0;
  position: relative;
  width: 100%;
}
.nt-solution-constructor-stage-progress:after {
  background-color: #fdb827;
  border-radius: 12px;
  content: '';
  height: 12px;
  left: 100%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
}
.nt-solution-constructor-stage-progress:before {
  background-color: #fdb827;
  border-radius: 12px;
  content: '';
  height: 12px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  display: none;
  left: 0;
}
.nt-solution-constructor-option {
  min-height: 48px;
  padding: 4px 90px 4px 60px;
  position: relative;
  line-height: 1.2;
  margin: 20px 0;
  white-space: nowrap;
}
.nt-solution-constructor-option:hover .nt-solution-constructor-option-icon {
  background-color: #fdb827;
}
.nt-solution-constructor-option.nt-active .nt-solution-constructor-option-icon {
  background-color: #fdb827;
}
.nt-solution-constructor-option-icon {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.2);
}
.nt-solution-constructor-option-title {
  font-size: 12px;
}
.nt-section-team {
  background-color: #fff;
}
.nt-section-body-team {
  position: relative;
}
.nt-team-members {
  position: relative;
}
.nt-team-members-roll {
  position: relative;
  overflow: hidden;
}
.nt-team-members-list {
  padding: 40px 10px 0;
  position: relative;
  transform: translateX(0);
  transition: transform 0.3s ease;
  white-space: nowrap;
  z-index: 0;
}
.nt-team-members-list-item {
  display: inline-block;
  padding-right: 20px;
  vertical-align: top;
}
.nt-team-members-list-item:last-child {
  padding-right: 0;
}
.nt-team-member-tile {
  background-color: #fff;
  display: block;
  position: relative;
}
.nt-team-member-tile:hover {
  color: inherit;
}
.nt-team-member-tile:hover .nt-team-member-photo {
  opacity: 0.2;
}
.nt-team-member-tile:hover .nt-team-member-contact {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}
.nt-team-member-photo {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: 400px;
  overflow: hidden;
  position: relative;
  transition: opacity 0.6s ease;
  width: 300px;
}
.nt-team-member-photo:after {
  background-image: inherit;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.nt-team-member-contact {
  background-color: #fdb827;
  border-radius: 90px;
  height: 90px;
  left: 50%;
  position: absolute;
  top: 200px;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translate(-50%, -50%) scale(0);
  width: 90px;
}
.nt-team-member-contact .nt-icon {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.nt-team-member-info {
  padding: 20px 0;
  text-align: center;
}
.nt-team-member-name {
  font-size: 1.5rem;
  padding: 8px;
}
.nt-team-member-role {
  padding: 8px;
}
.nt-team-members-actions .nt-button {
  height: 48px;
  padding: 0;
  width: 48px;
  position: relative;
}
.nt-team-members-actions .nt-button:hover {
  background-color: transparent;
}
.nt-team-members-actions .nt-icon {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.nt-team-members-action {
  margin: 0 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.nt-team-members-action-move-back {
  right: 100%;
}
.nt-team-members-action-move-forward {
  left: 100%;
}
@media screen and (max-width: 1130px) {
  .nt-team-members-list {
    padding: 40px 0 0;
  }
  .nt-team-members-list-item {
    padding: 0;
    width: 50%;
  }
  .nt-team-member-photo {
    width: auto;
  }
  .nt-team-members-actions {
    position: static;
    text-align: center;
  }
  .nt-team-members-action {
    display: inline-block;
    position: static;
    vertical-align: top;
    transform: none;
    margin: 0 10px;
  }
}
@media screen and (max-width: 1017px) {
  .nt-solution-constructor-wrapper {
    margin: 0 -20px;
    padding: 0 60px;
  }
  .nt-solution-constructor-step {
    width: 100%;
  }
  .nt-solution-constructor-stage {
    width: 100%;
  }
  .nt-quick-tests-list {
    padding: 20px 0;
  }
  .nt-quick-tests-list-item {
    width: 49%;
  }
  .nt-quick-test-link {
    padding: 20px;
    box-shadow: none !important;
  }
  .nt-quick-test-label {
    padding-right: 40px;
  }
}
@media screen and (max-width: 910px) {
  ul.nt-site-menu {
    float: right;
  }
  .nt-section-content-block .logo img {
    height: 60px;
    width: auto;
    display: block;
    vertical-align: middle;
    margin: auto;
    position: absolute;
    top: 150px;
    left: 10px;
  }
  .nt-section-header .nt-actions {
    margin: 0;
  }
  .nt-section-header .nt-actions .nt-button {
    width: 100%;
  }
  .nt-section-header .nt-action {
    margin: 10px 0;
    width: 100%;
  }
  .nt-section-body-header-landing .nt-section-title {
    font-size: 2.4rem;
  }
  .nt-navigation {
    padding: 0;
    margin-bottom: 45px;
  }
  .nt-navigation .nt-logo {
    width: 50%;
    height: 50%;
    margin-top: 2em;
  }
  ul.nt-site-meny li.icon {
    float: right;
    display: inline-block;
  }
  li.nt-site-menu-item.icon a {
    font-size: 20px;
  }
  #site_content .holiday {
    top: 11em;
  }
}
@media screen and (max-width: 910px) {
  ul.nt-site-menu li {
    display: none;
  }
  ul.nt-site-menu.responsive {
    float: right;
    position: relative;
  }
  ul.nt-site-menu li.icon {
    float: right;
    display: inline-block;
  }
  ul.nt-site-menu.responsive li.icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  ul.nt-site-menu.responsive li {
    float: none;
    display: inline;
    line-height: 5px;
  }
  ul.nt-site-menu.responsive li a {
    display: block;
    text-align: left;
  }
}
@media screen and (max-width: 910px) {
  .nt-advantages-list-item {
    width: 100%;
    margin: 20px 0;
  }
  .nt-section-content-trivia {
    margin: 40px auto;
  }
  .nt-section-footer {
    padding-bottom: 0;
  }
  .nt-footer-block {
    width: 100%;
    padding: 0;
  }
  .nt-footer-block-body {
    padding: 15px 0;
  }
  .nt-footer-block-title {
    margin: 0 0 10px;
  }
  .nt-footer-block-list {
    margin: 0;
  }
  .nt-footer-block-list > li {
    margin: 0;
  }
  .nt-footer-block-legal {
    padding: 15px 0;
  }
  .nt-footer-block-legal .nt-footer-block-body {
    width: 100%;
    text-align: left;
    padding: 0 0 10px;
  }
  .nt-team-members-list {
    padding: 40px 0 0;
  }
  .nt-team-members-list-item {
    padding: 0;
    width: 100%;
  }
  .nt-team-member-photo {
    width: auto;
  }
  .nt-team-members-actions {
    position: static;
    text-align: center;
  }
  .nt-team-members-action {
    display: inline-block;
    position: static;
    vertical-align: top;
    transform: none;
    margin: 0 10px;
  }
  .nt-projects-list {
    margin: 0;
  }
  .nt-projects-list-item {
    padding: 10px 0;
    width: 100%;
  }
  .nt-project-tile {
    width: auto;
  }
  .nt-quick-tests-list {
    padding: 20px 0;
  }
  .nt-quick-tests-list-item {
    width: 100%;
  }
  .nt-quick-test-link {
    padding: 20px;
    box-shadow: none !important;
  }
  .nt-quick-test-label {
    padding-right: 40px;
  }
}
